import React from "react";
import { isMobile } from "react-device-detect";
import "./Equation.css";
import "./EquationMobile.css";

// importing material-ui
import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

// importing utils
import { deleteVariables } from "../../utils/variablesHelper";

const Equation = ({ equationItem, equations, setEquations, setPriorityText, setEquationText, setCurrentId, variables, setVariables, ribbons }) => {
    const editHandler = () => {
        setCurrentId(equationItem.id);
        setPriorityText(equationItem.priority);
        setEquationText(equationItem.equation);
    };
    const deleteHandler = () => {
        // deleting variables
        setVariables(deleteVariables(equations, variables, equationItem.id));
        setEquations(equations.filter((item) => item.id !== equationItem.id));
    };

    if (isMobile) {
        return (
            <div className="Equation-mobile">
                <Grid item xs={1}>
                    <p className="equation-text-mobile">{equationItem.priority}</p>
                </Grid>
                <Grid item xs={9}>
                    <p className="equation-text-mobile">{equationItem.equation}</p>
                </Grid>
                <Grid className="equation-grid-mobile" item xs={1}>
                    <button className="edit-button-mobile" onClick={editHandler}><EditIcon fontSize="small" /></button>
                    {/* <span className="button-text">Edit</span> */}
                </Grid>
                <Grid className="equation-grid-mobile" item xs={1}>
                    <button className="delete-button-mobile" onClick={deleteHandler}><DeleteIcon fontSize="small" /></button>
                    {/* <span className="button-text">Delete</span> */}
                </Grid>
                {ribbons.indexOf(equationItem.id) !== -1 &&
                    <div className="ribbon"></div>
                }
            </div>
        );
    }
    else {
        return (
            <div className="Equation">
                <Grid item xs={1}>
                    <p className="equation-text">{equationItem.priority}</p>
                </Grid>
                <Grid item xs={9}>
                    <p className="equation-text">{equationItem.equation}</p>
                </Grid>
                <Grid className="equation-grid" item xs={1}>
                    <button className="edit-button" onClick={editHandler}><EditIcon /></button>
                    <span className="button-text">Edit</span>
                </Grid>
                <Grid className="equation-grid" item xs={1}>
                    <button className="delete-button" onClick={deleteHandler}><DeleteIcon /></button>
                    <span className="button-text">Delete</span>
                </Grid>
                {ribbons.indexOf(equationItem.id) !== -1 &&
                    <div className="ribbon"></div>
                }
            </div>
        );
    };
};

export default Equation;
