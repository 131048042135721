import React from "react";
import { isMobile } from "react-device-detect";
import "./EquationBlank.css"
import "./EquationBlankMobile.css";

// importing material-ui
import { Grid } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

const EquationBlank = () => {
    if (isMobile) {
        return (
            <div className="EquationBlank-mobile">
                <div className="equation-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="equation-blank-text-mobile">1</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p className="equation-blank-text-mobile">v = at</p>
                    </Grid>
                    <Grid className="equation-blank-grid-mobile" item xs={1}>
                        <p className="blank-edit-button-mobile"><EditIcon fontSize="small" /></p>
                    </Grid>
                    <Grid className="equation-blank-grid-mobile" item xs={1}>
                        <p className="blank-delete-button-mobile"><DeleteIcon fontSize="small" /></p>
                    </Grid>
                </div>
                <div className="equation-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="equation-blank-text-mobile">2</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p className="equation-blank-text-mobile">d = vt</p>
                    </Grid>
                    <Grid className="equation-blank-grid-mobile" item xs={1}>
                        <p className="blank-edit-button-mobile"><EditIcon fontSize="small" /></p>
                    </Grid>
                    <Grid className="equation-blank-grid-mobile" item xs={1}>
                        <p className="blank-delete-button-mobile"><DeleteIcon fontSize="small" /></p>
                    </Grid>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="EquationBlank">
                <div className="equation-blank-item">
                    <Grid item xs={1}>
                        <p className="equation-blank-text">1</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p className="equation-blank-text">v = at</p>
                    </Grid>
                    <Grid className="equation-blank-grid" item xs={1}>
                        <p className="blank-edit-button"><EditIcon /></p>
                    </Grid>
                    <Grid className="equation-blank-grid" item xs={1}>
                        <p className="blank-delete-button"><DeleteIcon /></p>
                    </Grid>
                </div>
                <div className="equation-blank-item">
                    <Grid item xs={1}>
                        <p className="equation-blank-text">2</p>
                    </Grid>
                    <Grid item xs={9}>
                        <p className="equation-blank-text">d = vt</p>
                    </Grid>
                    <Grid className="equation-blank-grid" item xs={1}>
                        <p className="blank-edit-button"><EditIcon /></p>
                    </Grid>
                    <Grid className="equation-blank-grid" item xs={1}>
                        <p className="blank-delete-button"><DeleteIcon /></p>
                    </Grid>
                </div>
            </div>
        );
    };
};

export default EquationBlank;
