import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./Nav.css";
import "./NavMobile.css";

const Nav = () => {
    if (isMobile) {
        return (
            <div className="Nav">
                <div className="nav-container-mobile">
                    <Link to="/">
                        <p className="home-nav">M<span>athena</span>tics</p>
                    </Link>
                    <Link to="/calculator">
                        <p className="calculator-nav">Calculator</p>
                    </Link>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="Nav">
                <div className="nav-container">
                    <Link to="/">
                        <p className="home-nav">M<span>athena</span>tics</p>
                    </Link>
                    <Link to="/calculator">
                        <p className="calculator-nav">Calculator</p>
                    </Link>
                </div>
            </div>
        );
    };
};

export default Nav;
