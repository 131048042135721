import React, { useState, useEffect } from "react";
import nextId from "react-id-generator";
import { isMobile } from "react-device-detect";
import "./Variable.css";
import "./VariableMobile.css";

// importing material-ui
import { Grid } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const Variable = ({ variableItem, variables, setVariables, ribbons }) => {
    // input handling
    const [valueText, setValueText] = useState("");
    const valueTextHandler = (e) => {
        if (e.target.value.match(/^[0-9-/.]+$/g) !== null || e.target.value === "") {
            setValueText(e.target.value);
        };
    };
    useEffect(() => {
        setVariables(
            variables.map((item) => {
                if (item.variable === variableItem.variable) {
                    return {
                        ...item, value: [valueText]
                    };
                };
                return item;
            })
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [valueText]);

    // select handing
    const selectHandler = () => {
        setVariables(
            variables.map((item) => {
                if (item.variable === variableItem.variable) {
                    setValueText("");
                    return {
                        ...item, value: [], select: !item.select
                    };
                };
                return item;
            })
        );
    };

    // if not selected to solve
    if (!variableItem.select) {
        if (isMobile) {
            return (
                <div className="Variable-mobile">
                    <Grid item xs={1}>
                        <p className="variable-text-mobile">{variableItem.variable}</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-text-mobile">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        <input
                            className="variable-input-mobile"
                            placeholder="Value"
                            type="text"
                            spellCheck="false"
                            autoComplete="false"
                            value={valueText}
                            onChange={valueTextHandler}
                        />
                    </Grid>
                    <Grid className="variable-grid-mobile" item xs={2}>
                        <button className="select-button-mobile" onClick={selectHandler}><CheckBoxOutlineBlankIcon fontSize="small" /></button>
                        {/* <span className="button-text">Select</span> */}
                    </Grid>
                    {ribbons.indexOf(variableItem.variable) !== -1 &&
                        <div className="ribbon"></div>
                    }
                </div>
            );
        }
        else {
            return (
                <div className="Variable">
                    <Grid item xs={1}>
                        <p className="variable-text">{variableItem.variable}</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-text">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        <input
                            className="variable-input"
                            placeholder="Value"
                            type="text"
                            spellCheck="false"
                            autoComplete="false"
                            value={valueText}
                            onChange={valueTextHandler}
                        />
                    </Grid>
                    <Grid className="variable-grid" item xs={2}>
                        <button className="select-button" onClick={selectHandler}><CheckBoxOutlineBlankIcon /></button>
                        <span className="button-text">Select</span>
                    </Grid>
                    {ribbons.indexOf(variableItem.variable) !== -1 &&
                        <div className="ribbon"></div>
                    }
                </div>
            );
        };
    }
    // if selected to solve
    else {
        if (isMobile) {
            return (
                <div className="Variable-mobile">
                    <Grid item xs={1}>
                        <p className="variable-text-mobile">{variableItem.variable}</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-text-mobile">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        {variableItem.value.map((value) => (
                            <div className="variable-value-mobile" key={nextId()}>{value}</div>
                        ))}
                    </Grid>
                    <Grid className="variable-grid-mobile" item xs={2}>
                        <button className="select-button-mobile" onClick={selectHandler}><CheckBoxIcon fontSize="small" /></button>
                        {/* <span className="button-text">Select</span> */}
                    </Grid>
                    {ribbons.indexOf(variableItem.variable) !== -1 &&
                        <div className="ribbon"></div>
                    }
                </div>
            );
        }
        else {
            return (
                <div className="Variable">
                    <Grid item xs={1}>
                        <p className="variable-text">{variableItem.variable}</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-text">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        {variableItem.value.map((value) => (
                            <div className="variable-value" key={nextId()}>{value}</div>
                        ))}
                    </Grid>
                    <Grid className="variable-grid" item xs={2}>
                        <button className="select-button" onClick={selectHandler}><CheckBoxIcon /></button>
                        <span className="button-text">Select</span>
                    </Grid>
                    {ribbons.indexOf(variableItem.variable) !== -1 &&
                        <div className="ribbon"></div>
                    }
                </div>
            );
        };
    };
};

export default Variable;
