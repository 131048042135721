// called when adding equations
export function addVariables(variables, equation) {
    equation.split("").filter(element => (/[a-zA-Z]/).test(element)).forEach(function (element) {
        // variables array empty
        if (variables.length === 0) {
            variables = [{ variable: element, count: 1, value: [], select: false }];
            // console.log("Creating initial variable.");
        }
        // variables array not empty
        else {
            var exists = false;

            for (let i = 0; i < variables.length; i++) {
                if (variables[i].variable === element) {
                    exists = i;
                    break;
                };
            };

            // add new variable
            if (exists === false) {
                variables = [...variables, { variable: element, count: 1, value: [], select: false }];
                // console.log("Creating new variable.");
            }
            // edit old variable
            else {
                variables[exists] = { ...variables[exists], count: variables[exists].count + 1 };
                // console.log("Updating old variable.");
            };
        };
    });

    return variables;
};

// called when editing equations
export function editVariables(variables, oldEquation, newEquation) {
    // decrement old variables
    oldEquation.split("").filter(element => (/[a-zA-Z]/).test(element)).forEach(function (element) {
        var exists = false;

        for (let i = 0; i < variables.length; i++) {
            if (variables[i].variable === element) {
                exists = i;
                break;
            };
        };

        // decrement variable count
        if (exists !== false) {
            variables[exists] = { ...variables[exists], count: variables[exists].count - 1 };
            // console.log("Deleting variable.");
        };
    });

    // increment new variables
    variables = addVariables(variables, newEquation);

    // delete variables
    return variables.filter((item) => item.count > 0);
};

// called when deleting equations
export function deleteVariables(equations, variables, id) {
    // equation to delete index
    var index;

    for (let i = 0; i < equations.length; i++) {
        if (equations[i].id === id) {
            index = i;
            break;
        };
    };

    equations[index].equation.split("").filter(element => (/[a-zA-Z]/).test(element)).forEach(function (element) {
        var exists = false;

        for (let i = 0; i < variables.length; i++) {
            if (variables[i].variable === element) {
                exists = i;
                break;
            };
        };

        // decrement variable count
        if (exists !== false) {
            variables[exists] = { ...variables[exists], count: variables[exists].count - 1 };
            // console.log("Deleting variable.");
        };
    });

    // delete variables
    return variables.filter((item) => item.count > 0);
};
