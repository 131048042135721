import React from "react";
import emailjs from 'emailjs-com';
import { isMobile } from "react-device-detect";
import "./BugDialog.css";
import "./BugDialogMobile.css";

const BugDialog = ({ handleCloseBug }) => {
    function sendEmail(e) {
        e.preventDefault();

        // makes and sends email
        emailjs.sendForm('service_mathenatics', 'template_mathenatics_bug', e.target, 'user_36v5cfTDSFFrIxG7xV71b').then((result) => {
            // console.log(result.text);
        }, (error) => {
            // console.log(error.text);
        });

        handleCloseBug();
        e.target.reset();
    };

    if (isMobile) {
        return (
            <form className="BugDialog-mobile" onSubmit={sendEmail}>
                <p className="form-title-mobile">Bug Report</p>
                <input
                    className="form-text-mobile"
                    required
                    placeholder="Name"
                    name="name"
                    type="text"
                />
                <input
                    className="form-text-mobile"
                    required
                    placeholder="email@hena.com"
                    name="email"
                    type="email"
                />
                <textarea
                    className="form-textarea-mobile"
                    required
                    placeholder="Bug? Tell us how to recreate it. We ask for your name and email address for future correspondences. Put Anonymous if you do not want to share your name. Click outside the form to close."
                    name="message"
                />
                <div className="form-button-mobile">
                    <button className="form-submit-mobile" type="submit">Submit</button>
                </div>
            </form>
        );
    }
    else {
        return (
            <form className="BugDialog" onSubmit={sendEmail}>
                <p className="form-title">Bug Report</p>
                <input
                    className="form-text"
                    required
                    placeholder="Name"
                    name="name"
                    type="text"
                />
                <input
                    className="form-text"
                    required
                    placeholder="email@hena.com"
                    name="email"
                    type="email"
                />
                <textarea
                    className="form-textarea"
                    required
                    placeholder="Bug? Tell us how to recreate it. We ask for your name and email address for future correspondences. Put Anonymous if you do not want to share your name. Click outside the form to close."
                    name="message"
                />
                <div className="form-button">
                    <button className="form-submit" type="submit">Submit</button>
                </div>
            </form>
        );
    };
};

export default BugDialog;
