import React from "react";
import "./Variables.css";

// importing components
import Variable from "../Variable/Variable";
import VariableBlank from "../VariableBlank/VariableBlank";

const Variables = ({ variables, setVariables, ribbons }) => {
    // variables array not empty
    if (variables.length) {
        return (
            <div className="Variables">
                {variables.map((variableItem) => (
                    <Variable
                        key={variableItem.variable}
                        variableItem={variableItem}
                        variables={variables}
                        setVariables={setVariables}
                        ribbons={ribbons}
                    />
                ))}
            </div>
        );
    }
    // variables array empty
    else {
        return (
            <div className="Variables">
                <VariableBlank />
            </div>
        );
    };
};

export default Variables;
