import React from "react";
import { isMobile } from "react-device-detect";
import "./HelpDialog.css";
import "./HelpDialogMobile.css";

// importing material ui
import CloseIcon from "@material-ui/icons/Close";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

const HelpDialog = ({ helpOpen, setHelpOpen, helpNumber, setHelpNumber }) => {
    if (helpOpen) {
        // navigating
        function closeHandler() {
            setHelpOpen(false);
        };
        function beforeHandler() {
            if (helpNumber !== 1) {
                setHelpNumber(helpNumber - 1);
            };
        };
        function nextHandler() {
            if (helpNumber !== 24) {
                setHelpNumber(helpNumber + 1);
            };
        };

        if (isMobile) {
            return (
                <div className="HelpDialog-mobile">
                    <div className="help-controls-mobile">
                        {/* cards */}
                        {helpOpen && helpNumber === 1 && <p className="help-controls-title-mobile">Need Help?</p>}
                        {helpOpen && helpNumber === 2 && <p className="help-controls-title-mobile">Ranking</p>}
                        {helpOpen && helpNumber === 3 && <p className="help-controls-title-mobile">Adding Equations</p>}
                        {helpOpen && helpNumber === 4 && <p className="help-controls-title-mobile">Variable Table</p>}
                        {helpOpen && helpNumber === 5 && <p className="help-controls-title-mobile">Mistake?</p>}
                        {helpOpen && helpNumber === 6 && <p className="help-controls-title-mobile">Delete</p>}
                        {helpOpen && helpNumber === 7 && <p className="help-controls-title-mobile">Variable Values</p>}
                        {helpOpen && helpNumber === 8 && <p className="help-controls-title-mobile">Selecting Variables</p>}
                        {helpOpen && helpNumber === 9 && <p className="help-controls-title-mobile">Solving Variables</p>}
                        {helpOpen && helpNumber === 10 && <p className="help-controls-title-mobile">Solve!</p>}
                        {helpOpen && helpNumber === 11 && <p className="help-controls-title-mobile">Help Menu</p>}
                        {helpOpen && helpNumber === 12 && <p className="help-controls-title-mobile">The End.</p>}
                        {helpOpen && helpNumber === 13 && <p className="help-controls-title-mobile">Variable Tips 1/3</p>}
                        {helpOpen && helpNumber === 14 && <p className="help-controls-title-mobile">Variable Tips 2/3</p>}
                        {helpOpen && helpNumber === 15 && <p className="help-controls-title-mobile">Variable Tips 3/3</p>}
                        {helpOpen && helpNumber === 16 && <p className="help-controls-title-mobile">Equation Tips 1/9</p>}
                        {helpOpen && helpNumber === 17 && <p className="help-controls-title-mobile">Equation Tips 2/9</p>}
                        {helpOpen && helpNumber === 18 && <p className="help-controls-title-mobile">Equation Tips 3/9</p>}
                        {helpOpen && helpNumber === 19 && <p className="help-controls-title-mobile">Equation Tips 4/9</p>}
                        {helpOpen && helpNumber === 20 && <p className="help-controls-title-mobile">Equation Tips 5/9</p>}
                        {helpOpen && helpNumber === 21 && <p className="help-controls-title-mobile">Equation Tips 6/9</p>}
                        {helpOpen && helpNumber === 22 && <p className="help-controls-title-mobile">Equation Tips 7/9</p>}
                        {helpOpen && helpNumber === 23 && <p className="help-controls-title-mobile">Equation Tips 8/9</p>}
                        {helpOpen && helpNumber === 24 && <p className="help-controls-title-mobile">Equation Tips 9/9</p>}

                        <button className="help-controls-button-mobile" onClick={closeHandler}><CloseIcon fontSize="small" /></button>
                    </div>

                    {/* cards */}
                    {helpOpen && helpNumber === 1 && <p className="help-text-mobile">Follow this short tutorial to learn how to use the Mathenatics equation calculator!</p>}
                    {helpOpen && helpNumber === 2 && <p className="help-text-mobile">The order equations are solved. Leaving it blank inputs the next available rank.</p>}
                    {helpOpen && helpNumber === 3 && <p className="help-text-mobile">Create your equation on the equation line. Press 'Enter' or click the 'Add Equation' button to input equation.</p>}
                    {helpOpen && helpNumber === 4 && <p className="help-text-mobile">Once you enter an equation, its variables will be displayed to the right of the equation table.</p>}
                    {helpOpen && helpNumber === 5 && <p className="help-text-mobile">Edit an equation by clicking the pencil icon. Edit then click the 'Submit Edits' button or press 'Enter' when done.</p>}
                    {helpOpen && helpNumber === 6 && <p className="help-text-mobile">Remove equations by clicking the trash icon beside the pencil icon.</p>}
                    {helpOpen && helpNumber === 7 && <p className="help-text-mobile">For known variables, input its value on the 'Value' line to the right of the variable.</p>}
                    {helpOpen && helpNumber === 8 && <p className="help-text-mobile">For unknown variables, click the 'Select' checkbox to solve for them.</p>}
                    {helpOpen && helpNumber === 9 && <p className="help-text-mobile">Only one variable can be solved per equation. Once a variable is solved for, it can be inputted into other equations.</p>}
                    {helpOpen && helpNumber === 10 && <p className="help-text-mobile">Click the 'Solve' button on the bottom right corner of the screen to solve for the selected variables.</p>}
                    {helpOpen && helpNumber === 11 && <p className="help-text-mobile">You can view these instructions again by clicking the '?' icon on the bottom left corner of the screen.</p>}
                    {helpOpen && helpNumber === 12 && <p className="help-text-mobile">That is the basics of the Mathenatics equation calculator. For advanced tips, click the next arrow to continue reading.</p>}
                    {helpOpen && helpNumber === 13 && <p className="help-text-mobile">Being able to quickly change the value of variables lets you solve questions that use the same equation.</p>}
                    {helpOpen && helpNumber === 14 && <p className="help-text-mobile">Example: Finding the roots of a quadratic equation in standard form. Turn the coefficients and constants into variables.</p>}
                    {helpOpen && helpNumber === 15 && <p className="help-text-mobile">Solve for the roots of several quadratic equations by just changing the values of the coefficient and constant variables.</p>}
                    {helpOpen && helpNumber === 16 && <p className="help-text-mobile">Solve multi-step problems in one go by inputting variables from other equations into each other.</p>}
                    {helpOpen && helpNumber === 17 && <p className="help-text-mobile">Selected variables that are solved in previous equations can be plugged into equations with a higher ranking.</p>}
                    {helpOpen && helpNumber === 18 && <p className="help-text-mobile">Example: Finding the total displacement of an object given the acceleration and time travelling in a linear path.</p>}
                    {helpOpen && helpNumber === 19 && <p className="help-text-mobile">Assuming the object has an initial velocity and displacement of zero we can create the equation v=at.</p>}
                    {helpOpen && helpNumber === 20 && <p className="help-text-mobile">Enter the given values of acceleration(a) and time(t) and solve for v(velocity).</p>}
                    {helpOpen && helpNumber === 21 && <p className="help-text-mobile">Create another equation d=vt with a ranking greater than the previous equation.</p>}
                    {helpOpen && helpNumber === 22 && <p className="help-text-mobile">We only need to select the displacement(d) variable and click the 'Solve' button.</p>}
                    {helpOpen && helpNumber === 23 && <p className="help-text-mobile">The first equation will solve for (v) then plug in the value of (v) and (t) into the second equation.</p>}
                    {helpOpen && helpNumber === 24 && <p className="help-text-mobile">The second equation will then solve for (d), giving you the displacement.</p>}

                    <div className="help-controls-mobile">
                        <button className="help-controls-button-mobile" onClick={beforeHandler}><NavigateBeforeIcon fontSize="small" /></button>

                        {/* cards */}
                        {helpOpen && helpNumber <= 12 && <p className="help-controls-text-mobile">{helpNumber} / 12</p>}
                        {helpOpen && helpNumber > 12 && <p className="help-controls-text-mobile">{helpNumber} / 12+12</p>}

                        <button className="help-controls-button-mobile" onClick={nextHandler}><NavigateNextIcon fontSize="small" /></button>
                    </div>
                </div >
            );
        }
        else {
            return (
                <div className="HelpDialog">
                    <div className="help-controls">
                        {/* cards */}
                        {helpOpen && helpNumber === 1 && <p className="help-controls-title">Need Help?</p>}
                        {helpOpen && helpNumber === 2 && <p className="help-controls-title">Ranking</p>}
                        {helpOpen && helpNumber === 3 && <p className="help-controls-title">Adding Equations</p>}
                        {helpOpen && helpNumber === 4 && <p className="help-controls-title">Variable Table</p>}
                        {helpOpen && helpNumber === 5 && <p className="help-controls-title">Mistake?</p>}
                        {helpOpen && helpNumber === 6 && <p className="help-controls-title">Delete</p>}
                        {helpOpen && helpNumber === 7 && <p className="help-controls-title">Variable Values</p>}
                        {helpOpen && helpNumber === 8 && <p className="help-controls-title">Selecting Variables</p>}
                        {helpOpen && helpNumber === 9 && <p className="help-controls-title">Solving Variables</p>}
                        {helpOpen && helpNumber === 10 && <p className="help-controls-title">Solve!</p>}
                        {helpOpen && helpNumber === 11 && <p className="help-controls-title">Help Menu</p>}
                        {helpOpen && helpNumber === 12 && <p className="help-controls-title">The End.</p>}
                        {helpOpen && helpNumber === 13 && <p className="help-controls-title">Variable Tips 1/3</p>}
                        {helpOpen && helpNumber === 14 && <p className="help-controls-title">Variable Tips 2/3</p>}
                        {helpOpen && helpNumber === 15 && <p className="help-controls-title">Variable Tips 3/3</p>}
                        {helpOpen && helpNumber === 16 && <p className="help-controls-title">Equation Tips 1/9</p>}
                        {helpOpen && helpNumber === 17 && <p className="help-controls-title">Equation Tips 2/9</p>}
                        {helpOpen && helpNumber === 18 && <p className="help-controls-title">Equation Tips 3/9</p>}
                        {helpOpen && helpNumber === 19 && <p className="help-controls-title">Equation Tips 4/9</p>}
                        {helpOpen && helpNumber === 20 && <p className="help-controls-title">Equation Tips 5/9</p>}
                        {helpOpen && helpNumber === 21 && <p className="help-controls-title">Equation Tips 6/9</p>}
                        {helpOpen && helpNumber === 22 && <p className="help-controls-title">Equation Tips 7/9</p>}
                        {helpOpen && helpNumber === 23 && <p className="help-controls-title">Equation Tips 8/9</p>}
                        {helpOpen && helpNumber === 24 && <p className="help-controls-title">Equation Tips 9/9</p>}

                        <button className="help-controls-button" onClick={closeHandler}><CloseIcon /></button>
                    </div>

                    {/* cards */}
                    {helpOpen && helpNumber === 1 && <p className="help-text">Follow this short tutorial to learn how to use the Mathenatics equation calculator!</p>}
                    {helpOpen && helpNumber === 2 && <p className="help-text">The order equations are solved. Leaving it blank inputs the next available rank.</p>}
                    {helpOpen && helpNumber === 3 && <p className="help-text">Create your equation on the equation line. Press 'Enter' or click the 'Add Equation' button to input equation.</p>}
                    {helpOpen && helpNumber === 4 && <p className="help-text">Once you enter an equation, its variables will be displayed to the right of the equation table.</p>}
                    {helpOpen && helpNumber === 5 && <p className="help-text">Edit an equation by clicking the pencil icon. Edit then click the 'Submit Edits' button or press 'Enter' when done.</p>}
                    {helpOpen && helpNumber === 6 && <p className="help-text">Remove equations by clicking the trash icon beside the pencil icon.</p>}
                    {helpOpen && helpNumber === 7 && <p className="help-text">For known variables, input its value on the 'Value' line to the right of the variable.</p>}
                    {helpOpen && helpNumber === 8 && <p className="help-text">For unknown variables, click the 'Select' checkbox to solve for them.</p>}
                    {helpOpen && helpNumber === 9 && <p className="help-text">Only one variable can be solved per equation. Once a variable is solved for, it can be inputted into other equations.</p>}
                    {helpOpen && helpNumber === 10 && <p className="help-text">Click the 'Solve' button on the bottom right corner of the screen to solve for the selected variables.</p>}
                    {helpOpen && helpNumber === 11 && <p className="help-text">You can view these instructions again by clicking the '?' icon on the bottom left corner of the screen.</p>}
                    {helpOpen && helpNumber === 12 && <p className="help-text">That is the basics of the Mathenatics equation calculator. For advanced tips, click the next arrow to continue reading.</p>}
                    {helpOpen && helpNumber === 13 && <p className="help-text">Being able to quickly change the value of variables lets you solve questions that use the same equation.</p>}
                    {helpOpen && helpNumber === 14 && <p className="help-text">Example: Finding the roots of a quadratic equation in standard form. Turn the coefficients and constants into variables.</p>}
                    {helpOpen && helpNumber === 15 && <p className="help-text">Solve for the roots of several quadratic equations by just changing the values of the coefficient and constant variables.</p>}
                    {helpOpen && helpNumber === 16 && <p className="help-text">Solve multi-step problems in one go by inputting variables from other equations into each other.</p>}
                    {helpOpen && helpNumber === 17 && <p className="help-text">Selected variables that are solved in previous equations can be plugged into equations with a higher ranking.</p>}
                    {helpOpen && helpNumber === 18 && <p className="help-text">Example: Finding the total displacement of an object given the acceleration and time travelling in a linear path.</p>}
                    {helpOpen && helpNumber === 19 && <p className="help-text">Assuming the object has an initial velocity and displacement of zero we can create the equation v=at.</p>}
                    {helpOpen && helpNumber === 20 && <p className="help-text">Enter the given values of acceleration(a) and time(t) and solve for v(velocity).</p>}
                    {helpOpen && helpNumber === 21 && <p className="help-text">Create another equation d=vt with a ranking greater than the previous equation.</p>}
                    {helpOpen && helpNumber === 22 && <p className="help-text">We only need to select the displacement(d) variable and click the 'Solve' button.</p>}
                    {helpOpen && helpNumber === 23 && <p className="help-text">The first equation will solve for (v) then plug in the value of (v) and (t) into the second equation.</p>}
                    {helpOpen && helpNumber === 24 && <p className="help-text">The second equation will then solve for (d), giving you the displacement.</p>}

                    <div className="help-controls">
                        <button className="help-controls-button" onClick={beforeHandler}><NavigateBeforeIcon /></button>

                        {/* cards */}
                        {helpOpen && helpNumber <= 12 && <p className="help-controls-text">{helpNumber} / 12</p>}
                        {helpOpen && helpNumber > 12 && <p className="help-controls-text">{helpNumber} / 12+12</p>}

                        <button className="help-controls-button" onClick={nextHandler}><NavigateNextIcon /></button>
                    </div>
                </div >
            );
        };
    }
    else {
        return (
            <div></div>
        );
    };
};

export default HelpDialog;
