import React from "react";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import "./Footer.css";
import "./FooterMobile.css";

const Footer = () => {
    if (isMobile) {
        return (
            <div className="Footer">
                <div className="footer-container-mobile">
                    <Link to="/terms">
                        <p className="legal-nav">Terms</p>
                    </Link>
                    <p className="copyright">© 2021 Mathenatics</p>
                    <Link to="/privacy">
                        <p className="legal-nav">Privacy</p>
                    </Link>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="Footer">
                <div className="footer-container">
                    <Link to="/terms">
                        <p className="legal-nav">Terms</p>
                    </Link>
                    <p className="copyright">© 2021 Mathenatics</p>
                    <Link to="/privacy">
                        <p className="legal-nav">Privacy</p>
                    </Link>
                </div>
            </div>
        );
    };
};

export default Footer;
