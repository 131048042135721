import React, { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ReactGa from "react-ga";
import "./App.css";

// importing components
import Nav from "./components/Nav/Nav";
import Footer from "./components/Footer/Footer";

// importing containers
import Home from "./containers/Home/Home";
import Calculator from "./containers/Calculator/Calculator";
import Terms from "./containers/Terms/Terms";
import Privacy from "./containers/Privacy/Privacy";

function App() {
  // google analytics
  useEffect(() => {
    ReactGa.initialize("UA-206702265-1");

    // report page view
    ReactGa.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <Router>
      <div className="App">
        <Nav />
        <Switch>
          <Route exact path="/" component={props => <Home {...props} />} />
          <Route path="/calculator" component={props => <Calculator {...props} />} />
          <Route path="/terms" component={props => <Terms {...props} />} />
          <Route path="/privacy" component={props => <Privacy {...props} />} />
        </Switch>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
