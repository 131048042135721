import React, { useState, useEffect } from "react";
import { Prompt } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobile } from "react-device-detect";
import "./Calculator.css";
import "./CalculatorMobile.css";

// importing material-ui
import { Dialog, Snackbar } from "@material-ui/core";
import BugReportIcon from "@material-ui/icons/BugReport";
import HelpIcon from "@material-ui/icons/Help";
import MuiAlert from "@material-ui/lab/Alert";

// importing components
import Pad from "../../components/Pad/Pad";
import Equations from "../../components/Equations/Equations";
import Variables from "../../components/Variables/Variables";
import BugDialog from "../../components/BugDialog/BugDialog";
import HelpDialog from "../../components/HelpDialog/HelpDialog";

// importing utils
import { solve } from "../../utils/solve";

// error message styling
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
};

function Calculator() {
    // important arrays
    const [equations, setEquations] = useState([]);
    const [variables, setVariables] = useState([]);

    // creating equations
    const [priorityText, setPriorityText] = useState("");
    const [equationText, setEquationText] = useState("");

    // editing equations
    const [currentId, setCurrentId] = useState("");

    // solve button
    const solveHandler = () => {
        var answers = solve(equations, variables);
        setVariables([...answers[0]]);
        setErrorMessage(answers[1]);
        if (answers[1].length !== 0) {
            setOpen(true);
        };
        setRibbons(answers[2]);
    };

    // clear selected variables when equations updates
    // useEffect(() => {
    //     var variablesTemp = [];
    //     for (let i = 0; i < variables.length; i++) {
    //         if (variables[i].select) {
    //             variablesTemp.push({ ...variables[i], value: [] });
    //         }
    //         else {
    //             variablesTemp.push(variables[i]);
    //         };
    //     };
    //     setVariables(variablesTemp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [equations]);

    // bug dialog
    const [openBug, setOpenBug] = useState(false);
    const handleClickOpenBug = () => {
        setOpenBug(true);
    };
    const handleCloseBug = () => {
        setOpenBug(false);
    };

    // help dialog
    const [helpOpen, setHelpOpen] = useState(true);
    const [helpNumber, setHelpNumber] = useState(1);
    const handleClickOpenHelp = () => {
        setHelpOpen(!helpOpen);
    };

    // error message
    const [errorMessage, setErrorMessage] = useState([]);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const [ribbons, setRibbons] = useState([]);

    // unload warning
    useEffect(() => {
        if (equations.length !== 0 || variables.length !== 0 || priorityText !== "" || equationText !== "") {
            window.addEventListener("beforeunload", handleBeforeUnload);
            return () => {
                window.removeEventListener("beforeunload", handleBeforeUnload);
            };
        };
    }, [equations, variables, priorityText, equationText]);
    const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
    };

    return (
        <div className={`Calculator${isMobile ? "-mobile" : ""}`}>
            <Helmet>
                <title>Equation Calculator - Mathenatics</title>
                <meta name="description" content="Mathenatics equation calculator solves equations in an entirely new way by deconstructing equations into their constituting variables." />
                <meta name="keywords" content="Calculator, Equation, Math, Mathematics, Mathenatics, New, Solver, Variable" />
            </Helmet>

            <Prompt
                when={equations.length !== 0 || variables.length !== 0 || priorityText !== "" || equationText !== ""}
                message="Changes you made may not be saved."
            />

            {isMobile ? (
                <div className="calculator-container-mobile">
                    <section className="pad-section-mobile">
                        <div className="pad-container-mobile">
                            <Pad
                                priorityText={priorityText}
                                setPriorityText={setPriorityText}
                                equationText={equationText}
                                setEquationText={setEquationText}
                                equations={equations}
                                setEquations={setEquations}
                                currentId={currentId}
                                setCurrentId={setCurrentId}
                                variables={variables}
                                setVariables={setVariables}
                                setErrorMessage={setErrorMessage}
                                setOpen={setOpen}
                            />
                        </div>
                    </section>

                    <section className="main-section-mobile">
                        <div className="equation-container-mobile">
                            <p className={`main-heading-mobile${equations.length === 0 ? "-blank" : ""}`}>Equations</p>
                            <div className="equation-table-mobile">
                                <Equations
                                    equations={equations}
                                    setEquations={setEquations}
                                    setPriorityText={setPriorityText}
                                    setEquationText={setEquationText}
                                    setCurrentId={setCurrentId}
                                    variables={variables}
                                    setVariables={setVariables}
                                    ribbons={ribbons}
                                />
                            </div>
                        </div>
                        <div className="variable-container-mobile">
                            <p className={`main-heading-mobile${variables.length === 0 ? "-blank" : ""}`}>Variables</p>
                            <div className="variable-table-mobile">
                                <Variables
                                    variables={variables}
                                    setVariables={setVariables}
                                    ribbons={ribbons}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="control-section-mobile">
                        <div className="control-container-mobile">
                            <div className="buttons-container-mobile">
                                <button className="bug-button-mobile" onClick={handleClickOpenBug}><BugReportIcon fontSize="small" /></button>
                                <button className="help-button-mobile" onClick={handleClickOpenHelp}><HelpIcon fontSize="small" /></button>
                            </div>
                            <p className="message-mobile">Important: Negative exponents do not work yet.</p>
                            <button className="solve-button-mobile" onClick={solveHandler}>Solve!</button>
                        </div>
                    </section>
                </div>
            ) : (
                <div className="calculator-container">
                    <section className="pad-section">
                        <div className="pad-container">
                            <Pad
                                priorityText={priorityText}
                                setPriorityText={setPriorityText}
                                equationText={equationText}
                                setEquationText={setEquationText}
                                equations={equations}
                                setEquations={setEquations}
                                currentId={currentId}
                                setCurrentId={setCurrentId}
                                variables={variables}
                                setVariables={setVariables}
                                setErrorMessage={setErrorMessage}
                                setOpen={setOpen}
                            />
                        </div>
                    </section>

                    <section className="main-section">
                        <div className="equation-container">
                            <p className={`main-heading${equations.length === 0 ? "-blank" : ""}`}>Equations</p>
                            <div className="equation-table">
                                <Equations
                                    equations={equations}
                                    setEquations={setEquations}
                                    setPriorityText={setPriorityText}
                                    setEquationText={setEquationText}
                                    setCurrentId={setCurrentId}
                                    variables={variables}
                                    setVariables={setVariables}
                                    ribbons={ribbons}
                                />
                            </div>
                        </div>
                        <div className="variable-container">
                            <p className={`main-heading${variables.length === 0 ? "-blank" : ""}`}>Variables</p>
                            <div className="variable-table">
                                <Variables
                                    variables={variables}
                                    setVariables={setVariables}
                                    ribbons={ribbons}
                                />
                            </div>
                        </div>
                    </section>

                    <section className="control-section">
                        <div className="control-container">
                            <div className="buttons-container">
                                <button className="bug-button" onClick={handleClickOpenBug}><BugReportIcon /></button>
                                <button className="help-button" onClick={handleClickOpenHelp}><HelpIcon /></button>
                            </div>
                            <p className="message">Important: Negative exponents do not work yet.</p>
                            <button className="solve-button" onClick={solveHandler}>Solve!</button>
                        </div>
                    </section>
                </div>
            )}

            <Dialog open={openBug} onClose={handleCloseBug} maxWidth="md">
                <BugDialog handleCloseBug={handleCloseBug} />
            </Dialog>

            <HelpDialog
                helpOpen={helpOpen}
                setHelpOpen={setHelpOpen}
                helpNumber={helpNumber}
                setHelpNumber={setHelpNumber}
            />

            <Snackbar open={open} autoHideDuration={3000 * errorMessage.length} onClose={handleClose}>
                <Alert severity="error" onClose={handleClose}>
                    {errorMessage.join(" ")}
                </Alert>
            </Snackbar>

            {/* <div className="aaa">RAID: SHADOW LEGENDS!!!</div>
            <div className="bbb">AFK ARENA!!!</div> */}
        </div>
    );
};

export default Calculator;
