import React from "react";
import { isMobile } from "react-device-detect";
import "./VariableBlank.css";
import "./VariableBlankMobile.css";

// importing material-ui
import { Grid } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";

const VariableBlank = () => {
    if (isMobile) {
        return (
            <div className="VariableBlank-mobile">
                <div className="variable-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">v</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected-mobile" item xs={8}>
                        <p className="variable-blank-text-mobile">10</p>
                    </Grid>
                    <Grid className="variable-blank-grid-mobile" item xs={2}>
                        <p className="blank-select-button-mobile"><CheckBoxIcon fontSize="small" /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">a</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected-mobile" item xs={8}>
                        <p className="variable-blank-text-mobile">2</p>
                    </Grid>
                    <Grid className="variable-blank-grid-mobile" item xs={2}>
                        <p className="blank-select-button-mobile"><CheckBoxOutlineBlankIcon fontSize="small" /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">t</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        <p className="variable-blank-text-mobile">5</p>
                    </Grid>
                    <Grid className="variable-blank-grid-mobile" item xs={2}>
                        <p className="blank-select-button-mobile"><CheckBoxOutlineBlankIcon fontSize="small" /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item-mobile">
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">d</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text-mobile">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected-mobile" item xs={8}>
                        <p className="variable-blank-text-mobile">50</p>
                    </Grid>
                    <Grid className="variable-blank-grid-mobile" item xs={2}>
                        <p className="blank-select-button-mobile"><CheckBoxIcon fontSize="small" /></p>
                    </Grid>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="VariableBlank">
                <div className="variable-blank-item">
                    <Grid item xs={1}>
                        <p className="variable-blank-text">v</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected" item xs={8}>
                        <p className="variable-blank-text">10</p>
                    </Grid>
                    <Grid className="variable-blank-grid" item xs={2}>
                        <p className="blank-select-button"><CheckBoxIcon /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item">
                    <Grid item xs={1}>
                        <p className="variable-blank-text">a</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected" item xs={8}>
                        <p className="variable-blank-text">2</p>
                    </Grid>
                    <Grid className="variable-blank-grid" item xs={2}>
                        <p className="blank-select-button"><CheckBoxOutlineBlankIcon /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item">
                    <Grid item xs={1}>
                        <p className="variable-blank-text">t</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text">=</p>
                    </Grid>
                    <Grid item xs={8}>
                        <p className="variable-blank-text">5</p>
                    </Grid>
                    <Grid className="variable-blank-grid" item xs={2}>
                        <p className="blank-select-button"><CheckBoxOutlineBlankIcon /></p>
                    </Grid>
                </div>
                <div className="variable-blank-item">
                    <Grid item xs={1}>
                        <p className="variable-blank-text">d</p>
                    </Grid>
                    <Grid item xs={1}>
                        <p className="variable-blank-text">=</p>
                    </Grid>
                    <Grid className="variable-blank-unselected" item xs={8}>
                        <p className="variable-blank-text">50</p>
                    </Grid>
                    <Grid className="variable-blank-grid" item xs={2}>
                        <p className="blank-select-button"><CheckBoxIcon /></p>
                    </Grid>
                </div>
            </div>
        );
    };
};

export default VariableBlank;
