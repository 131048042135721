// validates pad inputs
export function validate(priority, equation) {
    var errors = [];

    // ----- priority validation -----
    if (priority === "") {
        errors.push("Ranking must be given.");
    };

    // ----- equation validation -----
    if (equation === "") {
        errors.push("Equation must be given.");
    };
    if ((equation.match(/[a-zA-Z]/g) || []).length <= 0) {
        errors.push("Equation must have variables.");
    };
    if ((equation.match(/=/g) || []).length !== 1) {
        errors.push("Equation must have 1 equals sign.");
    };
    if (equation.indexOf("=") === 1 || equation.indexOf("=") === equation.length - 2) {
        errors.push("Equation must have two expressions.");
    };

    // decimal check
    let numbers = equation.split(/[a-zA-Z+\-*/^()[\]{}=\s]/).filter(Boolean);

    for (let i = 0; i < numbers.length; i++) {
        if (numbers[i].split(".").length > 2) {
            errors.push("Decimals are used incorrectly.");
            break;
        };
    };

    // parentheses check
    var brackets = [];
    // left hand side
    for (let i = 0; i < equation.indexOf("=") - 1; i++) {
        if (equation[i] === "(") {
            brackets.push(equation[i]);

            if (i !== equation.indexOf("=") - 2 && equation[i + 1] === ")") {
                errors.push("Parentheses must not be empty.");
            };
        }
        else if (equation[i] === ")") {
            if (brackets.pop() !== "(") {
                errors.push("Parentheses must balance.");
            };
        };
    };
    if (brackets.length !== 0) {
        errors.push("Parentheses must balance.");
        brackets = [];
    };
    // right hand side
    for (let i = equation.indexOf("=") + 2; i < equation.length; i++) {
        if (equation[i] === "(") {
            brackets.push(equation[i]);

            if (i !== equation.length - 1 && equation[i + 1] === ")") {
                errors.push("Parentheses must not be empty.");
            };
        }
        else if (equation[i] === ")") {
            if (brackets.pop() !== "(") {
                errors.push("Parentheses must balance.");
            };
        };
    };
    if (brackets.length !== 0) {
        errors.push("Parentheses must balance.");
    };

    // leading and trailing check
    if ("*/^)]}".includes(equation[0]) ||
        "*/^)]}".includes(equation[equation.indexOf("=") + 2]) ||
        "+-*/^([{".includes(equation[equation.indexOf("=") - 2]) ||
        "+-*/^([{".includes(equation[equation.length - 1])) {

        errors.push("Symbols are placed incorrectly.");
    };

    // // adjacent check
    "+-*/".split("").forEach((i) => {
        "*/^)".split("").forEach((j) => {
            if (equation.includes(i.concat(j))) {
                errors.push("Symbols are used incorrectly.");
            };
        });
    });
    "^".split("").forEach((i) => {
        "+-*/^)".split("").forEach((j) => {
            if (equation.includes(i.concat(j))) {
                errors.push("Symbols are used incorrectly.");
            };
        });
    });
    ".".split("").forEach((i) => {
        ".".split("").forEach((j) => {
            if (equation.includes(i.concat(j))) {
                errors.push("Symbols are used incorrectly.");
            };
        });
    });
    "(".split("").forEach((i) => {
        "*/^".split("").forEach((j) => {
            if (equation.includes(i.concat(j))) {
                errors.push("Symbols are used incorrectly.");
            };
        });
    });
    // adjacent check database
    // +    */^)
    // -    */^)
    // *    */^)
    // /    */^)
    // ^    +-*/^)
    // .    .
    // (  */^
    // )  n/a

    return [...new Set(errors)]; // also removes duplicates
};
