import React from "react";
import "./Equations.css";

// importing components
import Equation from "../Equation/Equation";
import EquationBlank from "../EquationBlank/EquationBlank";

const Equations = ({ equations, setEquations, setPriorityText, setEquationText, setCurrentId, variables, setVariables, ribbons }) => {
    // equations array not empty
    if (equations.length) {
        // sort by priority
        var equationsTemp = equations.map(item => ({ ...item }));

        equationsTemp.sort(function (x, y) {
            return x.priority - y.priority;
        });

        return (
            <div className="Equations">
                {equationsTemp.map((equationItem) => (
                    <Equation
                        key={equationItem.id}
                        equationItem={equationItem}
                        equations={equations}
                        setEquations={setEquations}
                        setPriorityText={setPriorityText}
                        setEquationText={setEquationText}
                        setCurrentId={setCurrentId}
                        variables={variables}
                        setVariables={setVariables}
                        ribbons={ribbons}
                    />
                ))}
            </div>
        );
    }
    // equations array empty
    else {
        return (
            <div className="Equations">
                <EquationBlank />
            </div>
        );
    };
};

export default Equations;
