import { parse } from "algebra.js";

// solves equations
export function solve(equations, variables) {
    var errors = [];
    var ribbons = [];

    // checks duplicate priority
    var priorities = equations.map(function (item) {
        return item.priority
    });
    var duplicate = priorities.some(function (item, idx) {
        return priorities.indexOf(item) !== idx
    });
    if (duplicate) {
        errors.push("Equations must have unique ranking.");
    };

    // variables must have a value unless selected
    for (let i = 0; i < variables.length; i++) {
        if (!variables[i].select && variables[i].value.length === 0) {
            errors.push("Unselected variables must have values.");
            break;
        };
    };

    // if all unselected variables have a value
    if (errors.length === 0) {
        // [...equations, {priority, equation, id}]
        // [...variables, {variable, count, value, select}]
        // [...ribbons, "id"]

        // clears selected variables
        for (let i = 0; i < variables.length; i++) {
            if (variables[i].select === true) {
                variables[i].value = [];
            };
        };

        // sort by priority
        equations.sort(function (x, y) {
            return x.priority - y.priority;
        });

        // iterates through each equation
        for (let i = 0; i < equations.length; i++) {
            // for each equation

            // extract variables
            let eqVarsTemp = equations[i].equation.split("").filter(element => (/[a-zA-Z]/).test(element));
            var eqVariables = [];
            // eqVariables.push(eqVarsTemp[0]);
            // only inserts a variable once into eqVariables - no duplicates
            for (let j = 0; j < eqVarsTemp.length; j++) {
                if (!eqVariables.includes(eqVarsTemp[j])) {
                    eqVariables.push(eqVarsTemp[j]);
                };
            };
            // there is an easier way to do this, you can just filter out duplicates

            // only one variable can be selected
            var selectedCount = 0;
            var selectedVar;

            // iterates through each equation variable
            for (let j = 0; j < eqVariables.length; j++) {
                let index;

                for (let k = 0; k < variables.length; k++) {
                    if (variables[k].variable === eqVariables[j]) {
                        index = k;
                        break;
                    };
                };

                // only allows selected variables with no values to be a selectedVar
                // selectedVars from previous equations should have values
                if (variables[index].select && variables[index].value.length < 1) {
                    selectedCount++;
                    selectedVar = eqVariables[j];
                };
            };

            if (selectedCount > 1) {
                errors.push("Only one selected variable per equation.");
                continue; // skips to the next equation if error
            } else if (selectedCount === 0) {
                errors.push("No selected variables to solve or independent equations solve for the same variable.");
                continue; // skips to the next equation if error
            };

            // create values array that mirrors variables
            var values = [];
            var missingVal = false;

            // iterates through each equation variable
            for (let j = 0; j < eqVariables.length; j++) {
                if (eqVariables[j] === selectedVar) {
                    values.push([selectedVar]);
                }
                else {
                    let index;

                    for (let k = 0; k < variables.length; k++) {
                        if (variables[k].variable === eqVariables[j]) {
                            index = k;
                            break;
                        };
                    };

                    // check if variables have values
                    if (variables[index].value.length < 1) {
                        errors.push("Missing value(s) from previous equation(s)");
                        missingVal = true;
                        break;
                    };
                    values.push(variables[index].value);
                };
            };

            if (missingVal) {
                continue;   // skips to next equation if error
            };

            // undefined and DNE checks
            let noAns = false;

            for (let j = 0; j < values.length; j++) {
                if (values[j].includes("Undefined") || values[j].includes("DNE")) {
                    noAns = true;

                    for (let k = 0; k < variables.length; k++) {
                        if (variables[k].variable === selectedVar) {
                            variables[k].value.push("Undefined");
                            break;
                        };
                    };

                    break;
                };
            };

            if (noAns) {
                continue;
            };

            // generate all variable combinations
            function combinations(array, result, index) {
                if (!result) {
                    result = [];
                    index = 0;
                };
                if (index < array.length) {
                    array[index].forEach(function (element) {
                        var a = array.slice(0);
                        a.splice(index, 1, [element]);
                        combinations(a, result, index + 1);
                    });
                }
                else {
                    result.push(array.join(" "));
                };

                return result;
            };

            var varCombos = combinations(values);

            // make and replace an equation for each combination
            var eqCombos = [];

            // iterates through each combination
            for (let j = 0; j < varCombos.length; j++) {
                var eqTemp = equations[i].equation;
                var combo = varCombos[j].split(" ");

                // replacing
                for (let k = 0; k < eqVariables.length; k++) {
                    eqTemp = eqTemp.replaceAll(eqVariables[k], "(" + combo[k] + ")");
                };

                eqCombos.push(eqTemp);
            };

            // console.log(eqCombos);

            // solving each eqCombos
            for (let j = 0; j < eqCombos.length; j++) {
                try {
                    var ans = parse(eqCombos[j]).solveFor(selectedVar);
                    // console.log(ans);

                    // if answer is an array
                    if (Array.isArray(ans)) {
                        // if array empty
                        if (ans.length === 0) {
                            for (let k = 0; k < variables.length; k++) {
                                if (variables[k].variable === selectedVar) {
                                    variables[k].value.push("DNE");
                                };
                            };
                        }
                        else {
                            // runs through contents of array to check if they are fractions of numbers
                            for (let k = 0; k < ans.length; k++) {
                                // if answer is a fraction it is changed to a number
                                if (typeof ans[k] !== "number") {
                                    ans[k] = ans[k].numer / ans[k].denom;
                                };

                                // answers are passed into value array
                                for (let l = 0; l < variables.length; l++) {
                                    if (variables[l].variable === selectedVar && !variables[l].value.includes(ans[k])) {
                                        variables[l].value.push(ans[k]);
                                    };
                                };
                            };
                        };
                    }
                    // if answer is a fraction
                    else if (typeof ans !== "number") {
                        for (let k = 0; k < variables.length; k++) {
                            if (variables[k].variable === selectedVar) {
                                variables[k].value.push(ans.numer / ans.denom);
                            };
                        };
                    }
                    // if answer is a number
                    else {
                        for (let k = 0; k < variables.length; k++) {
                            if (variables[k].variable === selectedVar) {
                                variables[k].value.push(ans);
                            };
                        };
                    };
                }
                catch (error) {
                    if (error.toString().includes("Divide By Zero")) {
                        for (let k = 0; k < variables.length; k++) {
                            if (variables[k].variable === selectedVar) {
                                variables[k].value.push("Undefined");
                            };
                        };
                    }
                    else if (error.toString().includes("No Solution")) {
                        for (let k = 0; k < variables.length; k++) {
                            if (variables[k].variable === selectedVar) {
                                variables[k].value.push("DNE");
                            };
                        };
                    }
                    else if (error.toString().includes("Divisor must be of type Integer or Fraction")) {
                        errors.push("Calculator currently cannot solve equations with selected variables in the denominator.");
                        errors.push("Please rearrange.");
                    }
                    else {
                        // generic error message
                        errors.push("Equation cannot be solved.");
                        // console.log(error);
                    };
                };
            };

            /*
            algebra.js answer database

            objects
            Array [number, Fraction, ...]
            Fraction {numer: number, denom: number}

            equation errors
            throw new TypeError("Invalid Argument (" + rhs.toString() + "): Right-hand side must be of type Expression, Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + lhs.toString() + "): Left-hand side must be of type Expression.");
            throw new TypeError("Invalid Argument (" + variable.toString() + "): Variable does not exist in the equation.");
            throw new EvalError("No Solution");
            throw new EvalError("No Solution");

            expression errors
            throw new TypeError("Invalid Argument (" + variable.toString() + "): Argument must be of type String, Integer, Fraction or Term.");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Summand must be of type String, Expression, Term, Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Multiplicand must be of type String, Expression, Term, Fraction or Integer.");
            throw new EvalError("Divide By Zero");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Divisor must be of type Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Exponent must be of type Integer.");
            throw new TypeError("Invalid Argument (" + variable.toString() + "): Term initializer must be of type Variable.");
            throw new TypeError("Invalid Argument (" + term.toString() + "): Summand must be of type String, Expression, Term, Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + term.toString() + "): Subtrahend must be of type String, Expression, Term, Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Multiplicand must be of type String, Expression, Term, Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + a.toString() + "): Argument must be of type Fraction or Integer.");
            throw new TypeError("Invalid Argument (" + sub + "): Can only evaluate Expressions or Fractions.");
            throw new TypeError("Invalid Argument (" + variable.toString() + "): Variable initalizer must be of type String.");
            */
        };

        // if value bigger than 10^6 or smaller than 10^-6
        for (let i = 0; i < variables.length; i++) {
            for (let j = 0; j < variables[i].value.length; j++) {
                if (typeof variables[i].value[j] === "number" && (
                    variables[i].value[j] > 1000000 ||
                    variables[i].value[j] < -1000000 ||
                    (variables[i].value[j] < 0.000001 && variables[i].value[j] > 0) ||
                    (variables[i].value[j] > -0.000001 && variables[i].value[j] < 0))) {

                    variables[i].value[j] = variables[i].value[j].toExponential(5);
                };
            };
        };
    };

    return [variables, [...new Set(errors)], ribbons];
};
