import React from "react";
import nextId from "react-id-generator";
import { isMobile } from "react-device-detect";
import "./Pad.css";
import "./PadMobile.css";

// importing utils
import { format } from "../../utils/format";
import { validate } from "../../utils/validate";
import { addVariables, editVariables } from "../../utils/variablesHelper";

const Pad = ({ priorityText, setPriorityText, equationText, setEquationText, equations, setEquations, currentId, setCurrentId, variables, setVariables, setErrorMessage, setOpen }) => {
    // input handling
    const priorityTextHandler = (e) => {
        if ((e.target.value.match(/^[0-9]+$/g) !== null || e.target.value === "") && e.target.value.length <= 3) {
            setPriorityText(e.target.value);
        };
    };
    const equationTextHandler = (e) => {
        if (e.target.value.match(/^[a-zA-Z0-9+\-*/^.()[\]{}=\s]+$/g) !== null || e.target.value === "") {
            setEquationText(e.target.value);
        };
    };
    const submitHandler = (e) => {
        e.preventDefault();

        // sort by priority
        let rankArr = [];

        for (let i = 0; i < equations.length; i++) {
            rankArr.push(parseInt(equations[i].priority));
        };
        rankArr.sort(function (x, y) {
            return x - y;
        });

        // puts in rank when left blank
        if (priorityText === "") {
            if (rankArr.length > 0) {
                priorityText = (rankArr[rankArr.length - 1] + 1) % 999;

                for (let i = 0; i < rankArr.length; i++) {
                    if (priorityText === rankArr[i]) {
                        priorityText++;
                    }
                    else {
                        break;
                    };
                };
            }
            else {
                priorityText = 1;
            };
        };

        // if expression and not equation
        if (!equationText.includes("=")) {
            // finds unused variable
            const alphabet = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ".split("");
            let usedVar = [];

            if (variables.length === 0) {
                equationText = equationText.concat("=" + alphabet[0]);
            }
            else {
                for (let i = 0; i < variables.length; i++) {
                    usedVar.push(variables[i].variable);
                };

                for (let i = 0; i < alphabet.length; i++) {
                    if (!usedVar.includes(alphabet[i])) {
                        equationText = equationText.concat("=" + alphabet[i]);
                        break;
                    };
                };
            };
        };

        // formats then validates input
        var equationFormat = format(equationText);
        var errors = validate(priorityText, equationFormat);
        // console.log("Original:" + equationText);
        // console.log("Formatted:" + equationFormat);

        if (errors.length === 0) {
            // add new equation
            if (currentId === "") {
                setEquations(
                    [...equations, { priority: priorityText, equation: equationFormat, id: nextId() }]
                );
                // adding variables
                setVariables(addVariables(variables, equationFormat));
            }
            // edit old equation
            else {
                setEquations(
                    equations.map((item) => {
                        if (item.id === currentId) {
                            // editing variables
                            setVariables(editVariables(variables, item.equation, equationFormat));
                            return {
                                ...item, priority: priorityText, equation: equationFormat
                            };
                        };
                        return item;
                    })
                );
                setCurrentId("");
            };
            setPriorityText("");
            setEquationText("");
        }
        else {
            setErrorMessage(errors);
            setOpen(true);
        };
    };

    if (isMobile) {
        return (
            <form className="Pad-mobile">
                <input
                    className="priority-mobile"
                    placeholder="Ranking"
                    type="text"
                    spellCheck="false"
                    autoComplete="false"
                    value={priorityText}
                    onChange={priorityTextHandler}
                />
                <input
                    className="equation-mobile"
                    placeholder="Equation"
                    type="text"
                    spellCheck="false"
                    autoComplete="false"
                    value={equationText}
                    onChange={equationTextHandler}
                />
                <button className="submit-button-mobile" type="submit" onClick={submitHandler}>{`${currentId === "" ? "Add Equation" : "Submit Edits"}`}</button>
            </form>
        );
    }
    else {
        return (
            <form className="Pad">
                <input
                    className="priority"
                    placeholder="Ranking"
                    type="text"
                    spellCheck="false"
                    autoComplete="false"
                    value={priorityText}
                    onChange={priorityTextHandler}
                />
                <input
                    className="equation"
                    placeholder="Equation"
                    type="text"
                    spellCheck="false"
                    autoComplete="false"
                    value={equationText}
                    onChange={equationTextHandler}
                />
                <button className="submit-button" type="submit" onClick={submitHandler}>{`${currentId === "" ? "Add Equation" : "Submit Edits"}`}</button>
            </form>
        );
    };
};

export default Pad;
