// formats equation text
export function format(eqText) {
    // remove spaces
    eqText = eqText.replaceAll(" ", "")

    // replace brackets
    eqText = eqText.replaceAll("[", "(").replaceAll("{", "(").replaceAll("]", ")").replaceAll("}", ")");

    var equation = [];

    for (let i = 0; i < eqText.length; i++) {
        // add 0 before decimal
        if (eqText[i] === ".") {
            if (i === 0) {
                equation.push("0");
            }
            else if (!(/[0-9]/g).test(eqText[i - 1])) {
                equation.push("0");
            };
        };

        equation.push(eqText[i]);

        // add 0 after decimal
        if (eqText[i] === ".") {
            if (i === eqText.length - 1) {
                equation.push("0");
            }
            else if (!(/[0-9]/g).test(eqText[i + 1])) {
                equation.push("0");
            };
        };
    };

    return equation.join("").replaceAll("=", " = ");  // joins and adds spaces
};
