import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { isMobileOnly } from "react-device-detect";
import "./Home.css";
import "./HomeMobile.css";

// importing assets
import Nyle from "../../assets/Nyle.png";
import Joseph from "../../assets/Joseph.png";

function Home() {
    if (isMobileOnly) {
        return (
            <div className="Home">
                <Helmet>
                    <title>Mathenatics</title>
                    <meta name="description" content="Mathenatics is a free online mathematics help website created by students for students. Our equation calculator aims to speed up your homework." />
                    <meta name="keywords" content="Calculator, Chug, Easy, Free, Help, Math, Mathematics, Mathenatics, New, Online, Plug, Quick, Solver, Student, Tool" />
                </Helmet>

                <section className="hero-section-mobile">
                    <div className="hero-container-mobile">
                        <p className="hero-header-mobile">M<span>athena</span>tics</p>
                        <p className="hero-sub-mobile">Plug &amp; Chug Equation Calculator</p>
                        <p className="hero-sub-mobile">Made By Students For Students</p>
                    </div>
                </section>

                <section className="features-section-mobile">
                    <div className="features-container-mobile">
                        <p className="features-header-mobile">Speedrun Your Homework</p>
                        <Link to="/calculator">
                            <button className="primary-cta-mobile">Equation Calculator</button>
                        </Link>
                        <p className="upcoming-features-header-mobile">Upcoming Features</p>
                        <div className="upcoming-features-content-mobile">
                            <p>Select from list of common formulas</p>
                            <p>Equation pad with symbols</p>
                            <p>Dragging and dropping equations and variables</p>
                            <p>Quality of life updates every week</p>
                        </div>
                    </div>
                </section>

                <section className="about-section-mobile">
                    <div className="about-container-mobile">
                        <div className="about-text-container-mobile">
                            <p className="about-header-mobile">About</p>
                            <p className="about-content-mobile">
                                Mathenatics is a free online desktop mathematics help website created by students for students.
                                Homework can involve solving similar problems that require applying the same formulas.
                                Therefore, one often repeats the same calculations but with different values.
                                Understanding which formulas to use and which variables to solve for is the primary aspect of homework.
                                Repeatedly having to input equations and variables is tedious and unnecessary.
                                Our objective is to speed up homework with our equation calculator, allowing students more time to spend on things they enjoy instead.
                                The Mathenatics equation calculator deconstructs equations into their constituting variables.
                                One can then easily change the value of any variable and quickly solve for the selected variable.
                                One can also use solved variables in future equations to solve multi-step problems.
                                This approach is entirely new and is the hallmark of the Mathenatics equation calculator.
                                We plan to add more features in the future and are currently working on some right now.
                                We appreciate your support by using the website and turning off ad blocker.
                                Please send us any feedback to our email at mathenatics.creators@gmail.com, and we hope you witness our growth.
                            </p>
                        </div>
                        <div className="profile-container-mobile">
                            <div className="profile-mobile">
                                <img className="profile-picture-mobile" src={Nyle} alt="Nyle" />
                                <p className="profile-name-mobile">Nyle Fernan Melegrito</p>
                                <p className="profile-description-mobile">Software Engineering Student</p>
                            </div>
                            <div className="profile-mobile">
                                <img className="profile-picture-mobile" src={Joseph} alt="Joseph" />
                                <p className="profile-name-mobile">Joseph Tran</p>
                                <p className="profile-description-mobile">Aerospace Engineering Student</p>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
    else {
        return (
            <div className="Home">
                <Helmet>
                    <title>Mathenatics</title>
                    <meta name="description" content="Mathenatics is a free online mathematics help website created by students for students. Our equation calculator aims to speed up your homework." />
                    <meta name="keywords" content="Calculator, Chug, Easy, Free, Help, Math, Mathematics, Mathenatics, New, Online, Plug, Quick, Solver, Student, Tool" />
                </Helmet>

                <section className="hero-section">
                    <div className="hero-container">
                        <p className="hero-header">M<span>athena</span>tics</p>
                        <p className="hero-sub">Plug &amp; Chug Equation Calculator</p>
                        <p className="hero-sub">Made By Students For Students</p>
                    </div>
                </section>

                <section className="features-section">
                    <div className="features-container">
                        <p className="features-header">Speedrun Your Homework</p>
                        <Link to="/calculator">
                            <button className="primary-cta">Equation Calculator</button>
                        </Link>
                        <p className="upcoming-features-header">Upcoming Features</p>
                        <div className="upcoming-features-content">
                            <p>Select from list of common formulas</p>
                            <p>Equation pad with symbols</p>
                            <p>Dragging and dropping equations and variables</p>
                            <p>Quality of life updates every week</p>
                        </div>
                    </div>
                </section>

                <section className="about-section">
                    <div className="about-container">
                        <div className="profile">
                            <img className="profile-picture" src={Nyle} alt="Nyle" />
                            <p className="profile-name">Nyle Fernan Melegrito</p>
                            <p className="profile-description">Software Engineering Student</p>
                        </div>
                        <div className="about-text-container">
                            <p className="about-header">About</p>
                            <p className="about-content">
                                Mathenatics is a free online desktop mathematics help website created by students for students.
                                Homework can involve solving similar problems that require applying the same formulas.
                                Therefore, one often repeats the same calculations but with different values.
                                Understanding which formulas to use and which variables to solve for is the primary aspect of homework.
                                Repeatedly having to input equations and variables is tedious and unnecessary.
                                Our objective is to speed up homework with our equation calculator, allowing students more time to spend on things they enjoy instead.
                                The Mathenatics equation calculator deconstructs equations into their constituting variables.
                                One can then easily change the value of any variable and quickly solve for the selected variable.
                                One can also use solved variables in future equations to solve multi-step problems.
                                This approach is entirely new and is the hallmark of the Mathenatics equation calculator.
                                We plan to add more features in the future and are currently working on some right now.
                                We appreciate your support by using the website and turning off ad blocker.
                                Please send us any feedback to our email at mathenatics.creators@gmail.com, and we hope you witness our growth.
                            </p>
                        </div>
                        <div className="profile">
                            <img className="profile-picture" src={Joseph} alt="Joseph" />
                            <p className="profile-name">Joseph Tran</p>
                            <p className="profile-description">Aerospace Engineering Student</p>
                        </div>
                    </div>
                </section>
            </div>
        );
    };
};

export default Home;
